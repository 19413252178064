import { defineStore } from 'pinia';

export const useAppStore = defineStore('app', {
  state: () => ({
    loaded: false,
    busy: false,
    busyDescription: undefined as undefined | string,
    isSidebarOpened: false,
    isSearchOpened: false,
  }),
});
