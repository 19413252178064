<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import { TagsInputItem, type TagsInputItemProps, useForwardProps } from 'radix-vue';
import { cn } from '../../util';

const props = defineProps<TagsInputItemProps & { class?: HTMLAttributes['class'] }>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwardedProps = useForwardProps(delegatedProps);
</script>

<template>
  <TagsInputItem
    v-bind="forwardedProps"
    :class="
      cn(
        'bg-muted data-[state=active]:ring-ring ring-offset-card flex h-6 items-center rounded data-[state=active]:ring-2 data-[state=active]:ring-offset-2',
        props.class,
      )
    "
  >
    <slot />
  </TagsInputItem>
</template>
