<template>
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="m3.776 5.629-.016-.085a.358.358 0 0 1 .033-.054 1.32 1.32 0 0 1 .334-.298c.363-.245.938-.496 1.715-.718C7.387 4.033 9.564 3.75 12 3.75c2.436 0 4.613.283 6.158.724.777.222 1.352.473 1.715.718.18.122.282.225.334.298a.355.355 0 0 1 .034.055l-.017.084a1.322 1.322 0 0 1-.35.322c-.364.245-.939.495-1.716.718-1.545.44-3.722.724-6.158.724-2.436 0-4.613-.283-6.158-.724-.777-.223-1.352-.473-1.715-.718a1.32 1.32 0 0 1-.35-.322Zm16.038 2.045c-.376.163-.795.309-1.244.437-1.713.49-4.035.782-6.57.782S7.143 8.6 5.43 8.11a10.22 10.22 0 0 1-1.244-.436l2.118 10.591a.75.75 0 0 1 .018.17c.002.084.045.236.27.449.233.22.612.454 1.14.665 1.056.422 2.564.7 4.268.7 1.704 0 3.213-.278 4.267-.7.53-.211.908-.445 1.141-.665.223-.211.267-.362.27-.446a.748.748 0 0 1 .017-.166l2.12-10.599Zm-.637 10.838c-.027.59-.338 1.084-.738 1.462-.417.395-.98.716-1.615.97-1.272.508-2.978.806-4.824.806-1.846 0-3.552-.298-4.824-.807-.634-.253-1.197-.574-1.615-.969-.4-.378-.71-.871-.738-1.462L2.337 6.082a1.525 1.525 0 0 1-.087-.501v-.022c.006-.73.519-1.26 1.037-1.61.548-.37 1.292-.674 2.143-.917 1.713-.49 4.035-.782 6.57-.782s4.857.293 6.57.782c.851.243 1.595.547 2.142.917.517.349 1.028.876 1.038 1.6v.041a1.524 1.524 0 0 1-.087.49l-2.486 12.432Zm-10.865-7.35c1.116.147 2.366.23 3.688.23s2.572-.082 3.688-.23l.197 1.486a29.815 29.815 0 0 1-3.885.245c-1.383 0-2.7-.087-3.885-.245l.197-1.487Z"
      fill="currentColor"
    />
  </svg>
</template>
