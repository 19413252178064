<template>
  <div ref="container" class="space-y-4 p-8">
    <div class="flex items-center justify-between">
      <div class="text-muted-foreground text-xs font-medium uppercase">Manage</div>
    </div>
    <ul class="space-y-2">
      <router-link
        v-for="link in links.main"
        :key="link.name"
        v-slot="{ href, navigate, isActive }"
        :to="link.to"
        custom
      >
        <li
          :class="[
            isActive || subIsActive(`/${link.to.name.toLowerCase()}`)
              ? ''
              : 'text-muted-foreground hover:text-foreground',
          ]"
        >
          <a :href="href" class="flex items-center space-x-3" @click="navigate">
            <span
              class="h-6 w-6 flex-shrink-0"
              :class="{
                'text-primary': isActive || subIsActive(`/${link.to.name.toLowerCase()}`),
              }"
            >
              <v-project-icon v-if="link.name === 'Projects'" class="h-6 w-6 flex-shrink-0" />
              <v-stacklet-icon v-if="link.name === 'Stacklets'" class="h-6 w-6 flex-shrink-0" />
              <v-images-icon v-if="link.name === 'Images'" class="h-6 w-6 flex-shrink-0" />
              <v-network-icon v-if="link.name === 'Networking'" class="h-6 w-6 flex-shrink-0" />
              <v-bucket-icon v-if="link.name === 'Storages'" />
              <v-iaas-icon v-if="link.name === 'IaaS'" />
            </span>
            <span
              :class="[
                isActive || subIsActive(`/${link.to.name.toLowerCase()}`) ? 'font-medium' : '',
              ]"
              class="truncate"
              >{{ link.name }}</span
            >
          </a>
        </li>
      </router-link>
    </ul>
  </div>
</template>

<script setup lang="ts">
import VProjectIcon from './icons/Projects.vue';
import VImagesIcon from './icons/Images.vue';
import VStackletIcon from './icons/Stacklets.vue';
import VBucketIcon from './icons/Buckets.vue';
import VNetworkIcon from './icons/Network.vue';
import VIaasIcon from './icons/IaaS.vue';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { type UserFeature, useProfile } from '@/entities/profile';

const route = useRoute();
const container = ref(null);
const { data: profile } = useProfile();

const subIsActive = (input: string | string[]) => {
  const paths = Array.isArray(input) ? input : [input];

  return paths.some((path) => route.path.indexOf(path) === 0); // current path starts with this path string
};

const links = computed(() => {
  const isNetworkAvailable = (
    ['floating_ips', 'firewalls', 'domains', 'load_balancers', 'vpc'] as UserFeature[]
  ).some((feature) => profile.value?.features?.includes(feature));

  const main = [
    // {
    //   visible: true,
    //   name: 'Projects',
    //   to: { name: 'projects' },
    // },
    {
      visible: true,
      name: 'Stacklets',
      to: { name: 'stacklets' },
    },
    {
      visible: true,
      name: 'Images',
      to: { name: 'Images' },
    },
    {
      visible: isNetworkAvailable,
      name: 'Networking',
      to: { name: 'networking' },
    },
    {
      visible:
        profile.value?.features.includes('volumes') || profile.value?.features.includes('buckets'),
      name: 'Storages',
      to: {
        name: 'storages',
      },
    },
    {
      visible:
        profile.value?.features.includes('container_registry') ||
        profile.value?.features.includes('databases') ||
        profile.value?.features.includes('kubernetes'),
      name: 'IaaS',
      to: { name: 'iaas' },
    },
  ];
  return {
    main: main.filter((link) => link.visible),
  };
});
</script>
