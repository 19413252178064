export default {
  path: 'stacklets/create',
  component: () => import('@/views/dashboard/stacklets/create/root.vue'),
  children: [
    {
      path: '',
      name: 'stacklet/create',
      component: () => import('@/views/dashboard/stacklets/create/create.vue'),
    },
  ],
};
