import { getProfile } from '@/entities/profile';
import { useQuery } from '@tanstack/vue-query';

export function useProfile() {
  return useQuery({
    queryKey: ['profile'],
    queryFn: () => getProfile().then((response) => response.profile),
    staleTime: Infinity,
    gcTime: Infinity,
    retry: false,
  });
}
