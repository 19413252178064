<template>
  <div class="text-primary bg-stripe rounded p-4 text-xs leading-relaxed">
    <h4 class="mb-1 font-medium">Unlock features</h4>
    <p class="text-muted-foreground mb-4">Verify your account by adding credit card.</p>
    <div class="space-x-4">
      <router-link :to="{ name: 'billing' }" class="text-primary font-medium"
        >Verify account</router-link
      >
    </div>
  </div>
</template>
