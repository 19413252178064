<script setup lang="ts">
import { type HTMLAttributes, computed } from 'vue';
import {
  TagsInputRoot,
  type TagsInputRootEmits,
  type TagsInputRootProps,
  useForwardPropsEmits,
} from 'radix-vue';
import { cn } from '../../util';

const props = defineProps<TagsInputRootProps & { class?: HTMLAttributes['class'] }>();
const emits = defineEmits<TagsInputRootEmits>();

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props;

  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <TagsInputRoot
    v-bind="forwarded"
    :class="
      cn(
        'bg-card flex flex-wrap items-center gap-2 rounded-md border px-3 py-[7px] text-sm',
        props.class,
      )
    "
  >
    <slot />
  </TagsInputRoot>
</template>
