import tippy from 'tippy.js';
import { parseBindings } from './utils';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-toward-subtle.css';
import './overrides.css';

const key = '__vue__tooltip__';

export default {
  mounted(el, binding) {
    const { placement, content, ...options } = parseBindings(binding);

    el[key] = tippy(el, {
      appendTo: document.getElementById('popperjs-container'),
      placement: placement || 'top',
      delay: [200, 0],
      content,
      animation: 'shift-toward-subtle',
      ...options,
    });
  },
  updated(el, binding) {
    const options = parseBindings(binding);

    if (el[key]) {
      el[key].setProps(options);
    }
  },
  beforeUnmount(el) {
    el[key].destroy(el);
  },
}
