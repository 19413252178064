import request from '@/shared/api/request';

/**
 *
 * Performs logout.
 *
 * @returns {Promise}
 */
export const logout = (): Promise<unknown> =>
  request({
    url: '/account/logout/',
    method: 'GET',
    withCredentials: true,
  });
