import type { RouteRecordRaw } from 'vue-router';

export default {
  meta: { feature: 'databases' },
  path: 'iaas/databases/create/',
  component: () => import('@/views/dashboard/iaas/databases/root.vue'),
  children: [
    {
      path: '',
      name: 'database/create',
      component: () => import('@/views/dashboard/iaas/databases/create/create.vue'),
    },
  ],
} satisfies RouteRecordRaw;
