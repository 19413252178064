import type { RouteRecordRaw } from 'vue-router';

export default {
  path: '/auth',
  component: () => import('@/views/auth/root.vue'),
  redirect: () => ({ name: 'login' }),
  children: [
    {
      path: 'login',
      name: 'login',
      component: () => import('@/views/auth/login.vue'),
    },
    {
      path: 'registration',
      name: 'registration',
      component: () => import('@/views/auth/registration.vue'),
    },
    {
      path: 'code',
      name: 'code',
      component: () => import('@/views/auth/code.vue'),
    },
    {
      path: 'new-password',
      name: 'new-password',
      component: () => import('@/views/auth/new-password.vue'),
    },
    {
      path: 'reset-password',
      name: 'reset-password',
      component: () => import('@/views/auth/reset-password.vue'),
    },
  ],
} satisfies RouteRecordRaw;
