export function parseBindings (binding) {
  const options = {};

  if (typeof binding.value === 'object') {
    return binding.value;
  }

  if (binding.value) {
    options.content = binding.value;
  }

  if (binding.arg) {
    options.placement = binding.arg;
  }

  return options;
}
