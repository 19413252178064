import type { RouteRecordRaw } from 'vue-router';
import type { Profile } from '@/entities/profile';
import { getTeam } from '@/entities/team';
import { useQueryClient } from '@tanstack/vue-query';

export default {
  path: '/teams/:uuid',
  component: () => import('@/views/dashboard/teams/team/root.vue'),
  children: [
    {
      path: '',
      name: 'team',
      component: () => import('@/views/dashboard/teams/team/team.vue'),
    },
    {
      path: 'settings',
      name: 'team/settings',
      beforeEnter: async (to) => {
        const queryClient = useQueryClient();

        const team = await queryClient.ensureQueryData({
          queryKey: ['team', to.params.uuid],
          queryFn: () => getTeam(to.params.uuid as string).then((data) => data.team),
        });
        const profile = queryClient.getQueryData<Profile>(['profile']);

        if (profile?.uuid !== team?.ownerUuid) {
          return { name: '404' };
        }
      },
      component: () => import('@/views/dashboard/teams/team/settings.vue'),
    },
  ],
} satisfies RouteRecordRaw;
