import type { RouteRecordRaw } from 'vue-router';

export default {
  path: 'stacklets/:stacklet_id',
  component: () => import('@/views/dashboard/stacklets/stacklet/root.vue'),
  redirect: () => ({ name: 'stacklet/overview' }),
  children: [
    {
      path: 'overview',
      name: 'stacklet/overview',
      component: () => import('@/views/dashboard/stacklets/stacklet/overview.vue'),
    },
    {
      path: 'snapshots',
      name: 'stacklet/snapshots',
      component: () => import('@/views/dashboard/stacklets/stacklet/snapshots.vue'),
    },
    {
      path: 'backups',
      name: 'stacklet/backups',
      component: () => import('@/views/dashboard/stacklets/stacklet/backups.vue'),
    },
    {
      path: 'resize',
      name: 'stacklet/resize',
      component: () => import('@/views/dashboard/stacklets/stacklet/resize.vue'),
    },
    {
      path: 'graphs',
      name: 'stacklet/graphs',
      component: () => import('@/views/dashboard/stacklets/stacklet/graphs.vue'),
    },
    {
      path: 'volume',
      meta: { feature: 'volumes' },
      name: 'stacklet/volume',
      component: () => import('@/views/dashboard/stacklets/stacklet/volume.vue'),
    },
    {
      path: 'networking',
      name: 'stacklet/networking',
      component: () => import('@/views/dashboard/stacklets/stacklet/networking.vue'),
    },
    {
      path: 'firewalls',
      name: 'stacklet/firewall',
      component: () => import('@/views/dashboard/stacklets/stacklet/firewall.vue'),
    },
    {
      path: 'recovery',
      name: 'stacklet/recovery',
      component: () => import('@/views/dashboard/stacklets/stacklet/recovery.vue'),
    },
    {
      path: 'history',
      name: 'stacklet/history',
      component: () => import('@/views/dashboard/stacklets/stacklet/history.vue'),
    },
  ],
} satisfies RouteRecordRaw;
