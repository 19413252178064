import type { Ref } from 'vue';
import type { Profile } from '../types';
import { computed } from 'vue';
import { IS_BILLING_ENABLED } from '@/shared/config';

export function useProfileStatus(profile: Ref<Profile | undefined>) {
  const isUserComplete = computed(() =>
    IS_BILLING_ENABLED
      ? profile.value?.isEmailConfirmed && (profile.value?.isVerified || profile.value?.isMember)
      : profile.value?.isEmailConfirmed,
  );
  const isConfirmed = computed(() => !!profile.value?.isEmailConfirmed);
  const isVerified = computed(() => !!profile.value?.isVerified);
  const isMember = computed(() => !!profile.value?.isMember);

  return {
    isUserComplete,
    isConfirmed,
    isVerified,
    isMember,
  };
}
