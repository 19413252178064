import type { RouteRecordRaw } from 'vue-router';

export default {
  path: 'databases',
  component: () => import('@/views/dashboard/iaas/databases/root.vue'),
  children: [
    {
      meta: { feature: 'databases' },
      path: '',
      name: 'databases',
      component: () => import('@/views/dashboard/iaas/databases/list.vue'),
    },
  ],
} satisfies RouteRecordRaw;
