<template>
  <svg width="37" height="42" viewBox="0 0 44 49" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fill-rule="evenodd">
      <path
        d="M40.481 29.682l-16.01 9.684c-1.55.938-4.088.938-5.64 0l-16.01-9.684C1.271 28.744 0 26.44 0 24.564v8.93c0 1.876 1.27 4.18 2.821 5.118l16.01 9.685c1.552.937 4.09.937 5.64 0l16.01-9.685c1.551-.938 2.821-3.242 2.821-5.118v-8.93c0 1.876-1.27 4.18-2.82 5.118"
        fill="#17CDFC"
      />
      <path
        d="M40.481 20.925l-8.772 5.288-7.238 4.364c-1.55.935-4.088.935-5.64 0l-7.193-4.337-8.817-5.315C1.273 19.991.006 17.704 0 15.836v8.984c0 1.87 1.27 4.165 2.821 5.1l16.01 9.652c1.552.934 4.09.934 5.64 0l16.01-9.652c1.551-.935 2.821-3.23 2.821-5.1v-8.984c-.005 1.868-1.272 4.155-2.82 5.09"
        fill="#00B0DD"
      />
      <path
        d="M43.302 15.636v.01c-.006 1.892-1.272 4.209-2.82 5.155l-8.773 5.353-7.238-4.417c-1.55-.947-4.089-.947-5.64 0L11.682 26.1l-.045.082L2.821 20.8C1.273 19.855.005 17.538 0 15.647v-.011c.005-1.891 1.273-4.208 2.821-5.153L18.831.71c1.551-.947 4.09-.947 5.64 0l16.01 9.773c1.549.945 2.815 3.262 2.821 5.153"
        fill="#001D64"
      />
      <path
        d="M43.302 15.636v.01c-.006 1.892-1.272 4.209-2.82 5.155l-8.773 5.353-7.238-4.417c-1.55-.947-4.089-.947-5.64 0L11.682 26.1l-.045.082L2.821 20.8C1.273 19.855.005 17.538 0 15.647v-.011c.005-1.891 1.273-4.208 2.821-5.153L18.831.71c1.551-.947 4.09-.947 5.64 0l16.01 9.773c1.549.945 2.815 3.262 2.821 5.153"
        fill="#0061FF"
      />
    </g>
  </svg>
</template>
