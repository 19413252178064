import { type Project } from '@/entities/project';
import { type RequestMeta, type ResponseMeta } from '@/shared/api';
import request from '@/shared/api/request';

export const getProjects = (
  params?: RequestMeta,
): Promise<{ projects: Project[]; meta: ResponseMeta }> =>
  request({
    url: '/projects/',
    params,
  });
