<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="m9.563 12.889-1.66 1.778 1.66 1.778M14.437 13l1.66 1.778-1.66 1.778"
    />
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-width="1.5"
      d="m11.637 17.333.83-5.333"
    />
    <path
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M14.333 3.5v5h4.2"
    />
    <path
      stroke="currentColor"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M5 4.5a1 1 0 0 1 1-1h9.111L19 8v11.5a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-15Z"
    />
  </svg>
</template>
