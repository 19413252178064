<template>
  <section v-for="(items, category) in categories" :key="category">
    <div class="mb-2 px-6">
      <h4 class="text-base font-medium capitalize">
        {{ category.toString().replaceAll('_', ' ') }}
      </h4>
    </div>
    <ul class="space-y-2 divide-y px-6">
      <v-result-item
        v-for="item in items"
        :key="item.name"
        :item="item"
        :category="category as string"
        :highlighted="highlightedIndex === findHighlightedIndex(item)"
        @mouseover="emit('mouseover', findHighlightedIndex(item))"
        @click="emit('click')"
      ></v-result-item>
    </ul>
  </section>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue';
import { nanoid } from 'nanoid';
import VResultItem from '../result-item.vue';

const emit = defineEmits(['click', 'mouseover']);

const props = defineProps({
  results: {
    type: Array as PropType<{ type: string }[]>,
    required: true,
  },
  highlightedIndex: {
    type: Number,
    default: -1,
  },
});
const _results = computed(() => props.results.map((result) => ({ ...result, _uuid: nanoid() })));

const categories = computed(() => {
  const list = {} as { [key: string]: any[] };

  _results.value.forEach((item) => {
    if (list[item.type] === undefined) {
      list[item.type] = [];
    }
  });

  Object.keys(list).forEach((category) => {
    _results.value.forEach((item) => {
      if (item.type === category) {
        list[item.type].push(item);
      }
    });
  });

  return list;
});

const findHighlightedIndex = (item) => {
  return _results.value.findIndex((_result) => _result._uuid === item._uuid);
};
</script>
