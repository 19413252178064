import type { RouteRecordRaw } from 'vue-router';

export default {
  path: 'tags/:tagName',
  component: () => import('@/views/dashboard/tag/root.vue'),
  children: [
    {
      path: '',
      meta: { requiresAuth: true },
      name: 'Tag',
      component: () => import('@/views/dashboard/tag/tag.vue'),
    },
  ],
} satisfies RouteRecordRaw;
