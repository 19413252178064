import type { CookieSetOptions } from 'universal-cookie';
export const ENV = import.meta.env.MODE;
export const ENV_PRODUCTION = ENV === 'production' || ENV === 'staging';
export const SITE_DOMAIN = import.meta.env.VITE_SITE_DOMAIN;
export const COMMUNITY_DOMAIN = import.meta.env.VITE_COMMUNITY_DOMAIN;
export const CDN_DOMAIN = import.meta.env.VITE_CDN_DOMAIN;
export const BUCKETS_SUBDOMAIN = import.meta.env.VITE_BUCKETS_SUBDOMAIN;
export const REGISTRY_DOMAIN = import.meta.env.VITE_REGISTRY_DOMAIN;
export const API_ROOT = import.meta.env.VITE_PROXY || import.meta.env.VITE_API_ROOT;
export const API_PREFIX = import.meta.env.VITE_API_PREFIX;
export const CHECK_TASK_INTERVAL = 5000;
export const SEARCH_HISTORY_UUID_KEY = 'simplystack:search:history';
export const CONTENT_TYPES = [
  {
    id: 'application/javascript',
    label: 'application/javascript',
    value: 'application/javascript',
  },
  { id: 'application/json', label: 'application/json', value: 'application/json' },
  { id: 'application/msword', label: 'application/msword', value: 'application/msword' },
  {
    id: 'application/octet-stream',
    label: 'application/octet-stream',
    value: 'application/octet-stream',
  },
  { id: 'application/ogg', label: 'application/ogg', value: 'application/ogg' },
  { id: 'application/pdf', label: 'application/pdf', value: 'application/pdf' },
  {
    id: 'application/x-shockwave-flash',
    label: 'application/x-shockwave-flash',
    value: 'application/x-shockwave-flash',
  },
  { id: 'application/xml', label: 'application/xml', value: 'application/xml' },
  { id: 'application/zip', label: 'application/zip', value: 'application/zip' },
  { id: 'audio/aac', label: 'audio/aac', value: 'audio/aac' },
  { id: 'audio/ogg', label: 'audio/ogg', value: 'audio/ogg' },
  { id: 'audio/webm', label: 'audio/webm', value: 'audio/webm' },
  { id: 'audio/x-wav', label: 'audio/x-wav', value: 'audio/x-wav' },
  { id: 'audio/midi', label: 'audio/midi', value: 'audio/midi' },
  { id: 'binary/octet-stream', label: 'binary/octet-stream', value: 'binary/octet-stream' },
  { id: 'font/collection', label: 'font/collection', value: 'font/collection' },
  { id: 'font/otf', label: 'font/otf', value: 'font/otf' },
  { id: 'font/ttf', label: 'font/ttf', value: 'font/ttf' },
  { id: 'font/woff', label: 'font/woff', value: 'font/woff' },
  { id: 'font/woff2', label: 'font/woff2', value: 'font/woff2' },
  { id: 'image/bmp', label: 'image/bmp', value: 'image/bmp' },
  { id: 'image/gif', label: 'image/gif', value: 'image/gif' },
  { id: 'image/jpeg', label: 'image/jpeg', value: 'image/jpeg' },
  { id: 'image/jpg', label: 'image/jpg', value: 'image/jpg' },
  { id: 'image/png', label: 'image/png', value: 'image/png' },
  { id: 'image/svg+xml', label: 'image/svg+xml', value: 'image/svg+xml' },
  { id: 'image/tiff', label: 'image/tiff', value: 'image/tiff' },
  { id: 'image/webp', label: 'image/webp', value: 'image/webp' },
  { id: 'image/x-icon', label: 'image/x-icon', value: 'image/x-icon' },
  { id: 'text/calendar', label: 'text/calendar', value: 'text/calendar' },
  { id: 'text/csv', label: 'text/csv', value: 'text/csv' },
  { id: 'text/css', label: 'text/css', value: 'text/css' },
  { id: 'text/html', label: 'text/html', value: 'text/html' },
  { id: 'text/plain', label: 'text/plain', value: 'text/plain' },
  { id: 'text/rtf', label: 'text/rtf', value: 'text/rtf' },
  { id: 'text/javascript', label: 'text/javascript', value: 'text/javascript' },
  { id: 'video/h264', label: 'video/h264', value: 'video/h264' },
  { id: 'video/mpeg', label: 'video/mpeg', value: 'video/mpeg' },
  { id: 'video/ogg', label: 'video/ogg', value: 'video/ogg' },
  { id: 'video/quicktime', label: 'video/quicktime', value: 'video/quicktime' },
  { id: 'video/webm', label: 'video/webm', value: 'video/webm' },
];
export const CUSTOM_IMAGE_EXTENSTIONS = ['img', 'vmdk', 'ovf', 'qcow', 'qcow2', 'vdi', 'vhdx'];
export const SENTRY_KEY = import.meta.env.VITE_SENTRY_KEY;
export const DEFAULT_THEME = 'light';
export const VOLUME_PRICE_PER_HOUR = 0.00014;
export const TAG_PER_PAGE = 200;
export const PAYPAL_OPTIONS = [
  {
    text: '$5.00 USD',
    value: 'Pay5',
  },
  {
    text: '$10.00 USD',
    value: 'Pay10',
  },
  {
    text: '$25.00 USD',
    value: 'Pay25',
  },
  {
    text: '$50.00 USD',
    value: 'Pay50',
  },
  {
    text: '$100.00 USD',
    value: 'Pay100',
  },
  {
    text: '$250.00 USD',
    value: 'Pay250',
  },
  {
    text: '$500.00 USD',
    value: 'Pay500',
  },
  {
    text: '$1000.00 USD',
    value: 'Pay1000',
  },
  {
    text: '$2500.00 USD',
    value: 'Pay2500',
  },
];
export const IS_HELPDESK_ENABLED = import.meta.env.VITE_HELPDESK
  ? import.meta.env.VITE_HELPDESK === 'true'
  : true;
export const IS_BILLING_ENABLED = import.meta.env.VITE_BILLING
  ? import.meta.env.VITE_BILLING === 'true'
  : true;
export const IS_CONSOLE_ENABLED = import.meta.env.VITE_CONSOLE
  ? import.meta.env.VITE_CONSOLE === 'true'
  : true;
export const TEAM_COOKIE_CONFIG: CookieSetOptions = {
  path: '/',
  httpOnly: false,
  secure: false,
  expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365),
  maxAge: 1000 * 60 * 60 * 24 * 365,
  sameSite: 'lax',
};
