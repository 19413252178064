import type { RouteRecordRaw } from 'vue-router';

export default {
  meta: { feature: 'load_balancers' },
  path: 'networking/load-balancers/:uuid',
  component: () => import('@/views/dashboard/networking/loadbalancers/root.vue'),
  redirect: () => ({ name: 'loadbalancer/stacklets' }),
  children: [
    {
      path: 'stacklets',
      name: 'loadbalancer/stacklets',
      component: () => import('@/views/dashboard/networking/loadbalancers/stacklets.vue'),
    },
    {
      path: 'graphs',
      name: 'loadbalancer/graphs',
      component: () => import('@/views/dashboard/networking/loadbalancers/graphs.vue'),
    },
    {
      path: 'settings',
      name: 'loadbalancer/settings',
      component: () => import('@/views/dashboard/networking/loadbalancers/settings.vue'),
    },
  ],
} satisfies RouteRecordRaw;
