import { type Profile } from '@/entities/profile';
import request from '@/shared/api/request';

/**
 *
 * Returns the current user Profile.
 *
 * @returns {Promise}
 */
export const getProfile = (): Promise<{ profile: Profile }> =>
  request({
    url: '/account/profile/',
    withCredentials: true,
    method: 'GET',
  });
