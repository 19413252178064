import type { Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { getTeam } from '@/entities/team';

export function useTeam(uuid: Ref<string>) {
  return useQuery({
    queryKey: ['team', uuid],
    queryFn: () => getTeam(uuid.value).then((response) => response.team),
    enabled: () => !!uuid.value,
    staleTime: Infinity,
  });
}
