<template>
  <div class="relative inline-flex justify-center space-x-1 rounded-full border p-1 shadow-sm">
    <button
      v-tooltip="'Dark'"
      type="button"
      :class="[
        theme === 'dark'
          ? 'bg-popover text-foreground shadow-sm'
          : 'text-muted-foreground border-transparent',
      ]"
      class="focus:ring-primary relative flex h-8 w-8 items-center justify-center whitespace-nowrap rounded-full border text-sm font-medium focus:z-10 focus:outline-none focus:ring-2"
      @click="updateTheme('dark')"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"
        />
      </svg>
    </button>
    <button
      v-tooltip="'Light'"
      type="button"
      :class="[
        theme === 'light' ? 'bg-popover shadow-sm' : 'text-muted-foreground border-transparent',
      ]"
      class="focus:ring-primary relative flex h-8 w-8 items-center justify-center whitespace-nowrap rounded-full border text-sm font-medium focus:z-10 focus:outline-none focus:ring-2"
      @click="updateTheme('light')"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728
        0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z"
        />
      </svg>
    </button>
    <button
      v-tooltip="'System'"
      type="button"
      :class="[
        theme === 'system' ? 'bg-popover shadow-sm' : 'text-muted-foreground border-transparent',
      ]"
      class="focus:ring-primary relative flex h-8 w-8 items-center justify-center whitespace-nowrap rounded-full border text-sm font-medium focus:z-10 focus:outline-none focus:ring-2"
      @click="updateTheme('system')"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0
        00-2 2v10a2 2 0 002 2z"
        />
      </svg>
    </button>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

const theme = ref();

const updateTheme = (_theme = 'system') => {
  if (_theme === 'system') {
    document.documentElement.setAttribute(
      'data-theme',
      window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
        ? 'dark'
        : 'light',
    );
  } else {
    document.documentElement.setAttribute('data-theme', _theme);
  }

  window.localStorage.setItem('theme', _theme);

  theme.value = _theme;
};

onMounted(() => {
  theme.value = window.localStorage.getItem('theme');
});
</script>
