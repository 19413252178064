import * as directives from './directives';
import * as components from './components';
import * as ui from './ui';
import { App } from 'vue';

// install function executed by Vue.use()
function install(app: App) {
  if (install.installed) return;
  install.installed = true;

  Object.keys(components).forEach((component) => {
    app.component(component, components[component]);
  });
  Object.keys(ui).forEach((component) => {
    app.component(component, ui[component]);
  });
  Object.keys(directives).forEach((directive) => {
    app.directive(directive, directives[directive]);
  });
}

// Create module definition for Vue.use()
const plugin = {
  install,
};

export * from './components';
export * from './hooks';
export * from './directives';
export * from './icons';

// Default export is library as a whole, registered via Vue.use()
export default plugin;
