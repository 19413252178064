import type { RouteRecordRaw } from 'vue-router';

export default {
  meta: { feature: 'volumes' },
  path: 'volumes',
  component: () => import('@/views/dashboard/storages/volumes/root.vue'),
  children: [
    {
      path: '',
      name: 'volumes',
      component: () => import('@/views/dashboard/storages/volumes/list.vue'),
    },
  ],
} satisfies RouteRecordRaw;
