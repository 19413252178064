import { z } from 'zod';
import { TeamSchema } from '@/entities/team';

export const ProjectSchema = z.object({
  uuid: z.string(),
  budget: z.string(),
  color: z.string(),
  createdAt: z.string(),
  description: z.string(),
  environment: z.enum(['Development', 'Staging', 'Production'] as const),
  isDefault: z.boolean(),
  name: z.string(),
  ownerUuid: z.string(),
  stackletsCount: z.number(),
  updatedAt: z.string(),
  team: z.optional(TeamSchema),
});

export type Project = z.infer<typeof ProjectSchema>;
