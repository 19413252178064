<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
    <g clip-path="url(#a)">
      <path
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="m7.223 7 2 2-2 2m3.56 0h2"
      />
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M14.43 16.229a.755.755 0 0 1-.18.021H12.5V17a.75.75 0 0 1-1.5 0v-.75h-.5V17A.75.75 0 0 1 9 17v-.75h-.25a.75.75 0 0 1 0-1.5h5.5l.045.001a2.75 2.75 0 1 1 .134 1.478Zm1.32-.979a1.25 1.25 0 1 1 2.5 0 1.25 1.25 0 0 1-2.5 0Z"
        clip-rule="evenodd"
      />
      <path
        fill="currentColor"
        fill-rule="evenodd"
        d="M5.75 4.5A.25.25 0 0 1 6 4.25h12.25v7.463a3.754 3.754 0 0 1 1.5.988V3.5a.75.75 0 0 0-.75-.75H6A1.75 1.75 0 0 0 4.25 4.5v15c0 .966.784 1.75 1.75 1.75h12a1.75 1.75 0 0 0 1.75-1.75v-1.7c-.409.44-.922.782-1.5.987v.713a.25.25 0 0 1-.25.25H6a.25.25 0 0 1-.25-.25v-15Zm12.5 13.2a2.75 2.75 0 0 0 0-4.9v4.9Z"
        clip-rule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a"><path fill="#fff" d="M0 0h24v24H0z" /></clipPath>
    </defs>
  </svg>
</template>
