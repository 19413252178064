import type { RouteLocationNormalized } from 'vue-router';
import { getProfile } from '@/entities/profile';
import { useQueryClient } from '@tanstack/vue-query';
import { isFeatureAvailable } from './feature-guard';
import closeSidebar from './close-sidebar';
import { getDefaultProject } from '@/entities/project';

export const beforeEach = async (to: RouteLocationNormalized) => {
  const queryClient = useQueryClient();

  try {
    const isProfileNeeded = to.matched.some((record) => record.meta.profile);

    if (isProfileNeeded) {
      const profile = await queryClient.ensureQueryData({
        queryKey: ['profile'],
        queryFn: () => getProfile().then((data) => data.profile),
        staleTime: Infinity,
        gcTime: Infinity,
        retry: false,
      });

      if (!isFeatureAvailable(profile, to)) {
        return { name: '404' };
      }

      // Redirect to the default project if the user is trying to access the default project
      if (to.params.project_uuid === 'default') {
        const project = await queryClient.ensureQueryData({
          queryKey: ['project', 'default'],
          queryFn: () => getDefaultProject().then((data) => data.project),
          gcTime: Infinity,
          staleTime: Infinity,
        });

        queryClient.setQueryData(['project', project.uuid], project);

        return { name: 'project', params: { project_uuid: project.uuid } };
      }
    }

    closeSidebar();
  } catch (error) {
    queryClient.clear();
    return { name: 'login' };
  }
};
