import { ENV, ENV_PRODUCTION, SENTRY_KEY } from '@/shared/config';
import { App } from 'vue';
import { Router } from 'vue-router';

export async function initSentry(app: App<Element>, router: Router): Promise<void> {
  const { init, browserTracingIntegration } = await import('@sentry/vue');

  if (ENV_PRODUCTION && SENTRY_KEY) {
    init({
      app,
      dsn: SENTRY_KEY,
      environment: ENV,
      tracePropagationTargets: ['cloud.hubgridcloud.com'],
      integrations: [
        browserTracingIntegration({
          router,
        }),
      ],
      tracesSampleRate: 1.0,
    });
  }
}
