import { type Profile } from '@/entities/profile';
import { RouteLocationNormalized } from 'vue-router';

/**
 *
 * @name isFeatureAvailable
 * @description Returns true or false whether route.meta.feature includes in user.features
 */
export const isFeatureAvailable = (user: Profile, to: RouteLocationNormalized) => {
  if (!to.meta) return true;
  return !(to.meta.feature && !user.features.some((feature) => to.meta.feature === feature));
};
