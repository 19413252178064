import type { RouteRecordRaw } from 'vue-router';

export default {
  meta: { feature: 'databases' },
  path: 'iaas/databases/:database_uuid',
  component: () => import('@/views/dashboard/iaas/databases/database/root.vue'),
  children: [
    { path: '', redirect: { name: 'database/overview' } },
    {
      path: 'overview',
      name: 'database/overview',
      component: () => import('@/views/dashboard/iaas/databases/database/overview.vue'),
    },
    {
      path: 'graphs',
      name: 'database/graphs',
      component: () => import('@/views/dashboard/iaas/databases/database/graphs.vue'),
    },
    {
      path: 'backups',
      name: 'database/backups',
      component: () => import('@/views/dashboard/iaas/databases/database/backups.vue'),
    },
    {
      path: 'snapshots',
      name: 'database/snapshots',
      component: () => import('@/views/dashboard/iaas/databases/database/snapshots/snapshots.vue'),
    },
    {
      path: 'firewalls',
      name: 'database/firewall',
      component: () => import('@/views/dashboard/iaas/databases/database/firewall/firewall.vue'),
    },
    {
      path: 'settings',
      name: 'database/settings',
      component: () => import('@/views/dashboard/iaas/databases/database/settings/settings.vue'),
    },
  ],
} satisfies RouteRecordRaw;
