import type { RouteRecordRaw } from 'vue-router';

export default {
  path: '/teams',
  component: () => import('@/views/dashboard/teams/root.vue'),
  redirect: () => ({ name: 'team/create' }),
  children: [
    {
      path: 'create',
      name: 'team/create',
      component: () => import('@/views/dashboard/teams/create.vue'),
    },
  ],
} satisfies RouteRecordRaw;
