<script setup lang="ts">
import type { HTMLAttributes } from 'vue';
import { cn } from '../../util';

const props = defineProps<{ class?: HTMLAttributes['class'] }>();
</script>

<template>
  <div :class="cn('flex flex-col gap-y-2 text-left', props.class)">
    <slot />
  </div>
</template>
