<template>
  <DropdownMenu v-if="data?.teams && profile">
    <DropdownMenuTrigger class="group/trigger">
      <div class="flex h-10 max-w-[200px] select-none items-center px-1">
        <div class="hidden min-w-0 text-right md:block">
          <!-- <h4 class="truncate text-sm font-medium">{{ username }}</h4> -->
          <!-- <p v-if="IS_BILLING_ENABLED" class="text-xs truncate text-muted-foreground">Balance: $38.99</p> -->
        </div>
        <img
          :src="profile.gravatarUrl"
          alt="Profile picture"
          class="h-9 w-9 flex-shrink-0 rounded-full bg-gray-300"
        />
        <ChevronDownIcon
          class="ml-1 h-4 w-4 flex-shrink-0 transform transition duration-200 ease-in-out group-data-[state=open]/trigger:rotate-180"
        />
      </div>
    </DropdownMenuTrigger>
    <DropdownMenuContent class="w-40 p-1" align="end">
      <DropdownMenuLabel>My account</DropdownMenuLabel>
      <DropdownMenuSeparator />
      <DropdownMenuGroup>
        <DropdownMenuItem as-child>
          <RouterLink :to="{ name: 'profile' }">
            <UserIcon class="mr-2 h-4 w-4" />
            Profile
          </RouterLink>
        </DropdownMenuItem>
        <DropdownMenuItem v-if="IS_BILLING_ENABLED ? (team ? isOwner : true) : false" as-child>
          <RouterLink :to="{ name: 'billing' }">
            <CreditCardIcon class="mr-2 h-4 w-4" />
            Billing
          </RouterLink>
        </DropdownMenuItem>
        <DropdownMenuItem as-child>
          <RouterLink :to="{ name: 'profile/notifications' }">
            <BellDotIcon class="mr-2 h-4 w-4" />
            Notifications
          </RouterLink>
        </DropdownMenuItem>
        <DropdownMenuItem as-child>
          <RouterLink :to="{ name: 'profile/logs' }">
            <HistoryIcon class="mr-2 h-4 w-4" />
            Logs
          </RouterLink>
        </DropdownMenuItem>
      </DropdownMenuGroup>
      <DropdownMenuSeparator />
      <DropdownMenuItem @click="handleLogout">
        <LogOutIcon class="mr-2 h-4 w-4" />
        <span>Log out</span>
      </DropdownMenuItem>
    </DropdownMenuContent>
  </DropdownMenu>
</template>

<script setup lang="ts">
import {
  LogOutIcon,
  UserIcon,
  BellDotIcon,
  CreditCardIcon,
  HistoryIcon,
  ChevronDownIcon,
} from 'lucide-vue-next';
import {
  DropdownMenu,
  DropdownMenuLabel,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'ui/src/ui/dropdown-menu';
import { computed, toRef } from 'vue';
import { IS_BILLING_ENABLED, TEAM_COOKIE_CONFIG } from '@/shared/config';
import { useRouter } from 'vue-router';
import { useTeam, useTeams } from '@/entities/team';
import { logout, useProfile } from '@/entities/profile';
import { useQueryClient } from '@tanstack/vue-query';
import { useCookies } from '@vueuse/integrations/useCookies';

const router = useRouter();
const queryClient = useQueryClient();
const { data: profile } = useProfile();
const { data } = useTeams();
const cookies = useCookies(['team'], { autoUpdateDependencies: true });

const currentTeamUuid = toRef(() => cookies.get('team'));

const { data: team } = useTeam(currentTeamUuid);

const isOwner = computed(() => {
  const owner = team.value?.members.find((member) => member.role === 'Owner');
  return !!owner && owner.uuid === profile.value?.uuid;
});

async function handleLogout() {
  await logout();
  window.localStorage.clear();
  queryClient.clear();
  cookies.remove('team', TEAM_COOKIE_CONFIG);
  router.push({ name: 'login', query: { logout: 'success' } }).catch(() => {});
}
</script>
