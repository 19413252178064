<template>
  <section class="">
    <div class="mb-2 px-6">
      <h4 class="text-base font-medium capitalize">Recent</h4>
    </div>
    <ul class="space-y-2 divide-y px-6">
      <!-- <li v-for="item in history" :key="item.name" class="hover:bg-stripe px-6 py-3">
        {{ item.name }}
      </li> -->
      <v-result-item
        v-for="item in _history"
        :key="item._uuid"
        :item="item"
        :category="item.type"
        :highlighted="highlightedIndex === findHighlightedIndex(item)"
        @mouseover="emit('mouseover', findHighlightedIndex(item))"
        @click="emit('click')"
      />
    </ul>
  </section>
</template>

<script setup lang="ts">
import VResultItem from '../result-item.vue';

import { nanoid } from 'nanoid';
import { computed } from 'vue';

const emit = defineEmits(['click', 'mouseover']);

const props = defineProps({
  history: {
    type: Array,
    required: true,
  },
  highlightedIndex: {
    type: Number,
    default: -1,
  },
});

const findHighlightedIndex = (item) => {
  return _history.value.findIndex((_item) => _item._uuid === item._uuid);
};

const _history = computed(() => props.history.map((item: any) => ({ ...item, _uuid: nanoid() })));
</script>
