import type { RouteRecordRaw } from 'vue-router';
import databases from './databases';
import kubernetes from './kubernetes';
import registry from './container-registry';

export default {
  name: 'iaas',
  path: '/iaas',
  component: () => import('@/views/dashboard/iaas/root.vue'),
  children: [databases, kubernetes, registry],
} satisfies RouteRecordRaw;
