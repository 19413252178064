import type { RouteRecordRaw } from 'vue-router';
import volumes from './volumes';
import buckets from './buckets';

export default {
  path: 'storages',
  name: 'storages',
  component: () => import('@/views/dashboard/storages/root.vue'),
  children: [volumes, buckets],
} satisfies RouteRecordRaw;
