import tippy from 'tippy.js';
import { onMounted, ref } from 'vue';

const defaults = {
  appendTo: document.getElementById('popperjs-container'),
  trigger: 'manual',
  animation: 'shift-toward-subtle',
}

export default function useTooltip (options) {
  const reference = ref();
  const instance = ref();

  onMounted(() => {
    instance.value = tippy(reference.value, { ...defaults , ...options });
  });

  const show = (content) => {
    instance.value.setProps({ content });
    instance.value.show();
  };

  const hide = () => {
    instance.value.hide();
  };

  return {
    instance,
    reference,
    show,
    hide,
  }
}
