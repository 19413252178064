<template>
  <form class="flex items-center border-b px-6" @submit.prevent>
    <Spinner v-if="loading" :size="24" class="mr-4" />
    <SearchIcon v-else class="mr-4 h-4 w-4" />
    <input
      ref="input"
      v-model="query"
      class="bg-popover h-16 flex-1 outline-none"
      type="text"
      placeholder="Search..."
      autocomplete="off"
      autocorrect="off"
      spellcheck="false"
      @input="emit('update:query', query)"
      @keydown.up.prevent="emit('highlight:up')"
      @keydown.down.prevent="emit('highlight:down')"
    />
    <Button variant="outline" size="icon" @click="emit('close')">
      <XIcon class="h-4 w-4" />
    </Button>
  </form>
</template>

<script setup lang="ts">
import { SearchIcon, XIcon } from 'lucide-vue-next';
import { Button } from 'ui/src/ui/button';
import { nextTick, onMounted, Ref, ref } from 'vue';
import { Spinner } from 'ui/src/ui/spinner';

const input = ref() as Ref<HTMLElement | undefined>;
const query = ref('');

const emit = defineEmits(['close', 'update:query', 'highlight:up', 'highlight:down']);

const focus = () => input.value?.focus();

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});

defineExpose({ focus });

onMounted(() => {
  nextTick(() => {
    input.value && input.value.focus();
  });
});
</script>
