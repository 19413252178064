import { type RequestMeta, type ResponseMeta } from '@/shared/api';
import request from '@/shared/api/request';

interface Database {
  type: 'database';
  uuid: string;
  name: string;
  engine: string;
  version: string;
  projectUuid: string;
  tags: string[];
}

interface Stacklet {
  type: 'stacklet';
  id: number;
  name: string;
  status: string;
  ipv4Address: string;
  projectUuid: string;
  tags: string[];
}

interface Firewall {
  type: 'firewall';
  uuid: string;
  name: string;
}

interface Domain {
  type: 'domain';
  name: string;
  project_uuid: string;
  record_stats: Record<string, number>[];
}

interface Kubernetes {
  type: 'kubernetes';
  uuid: string;
  name: string;
  version: string;
  project_uuid: string;
  tags: string[];
}

export type Results = Database | Stacklet | Firewall | Domain | Kubernetes;

export const search = (params: RequestMeta): Promise<{ meta: ResponseMeta; results: Results[] }> =>
  request({
    url: '/search/',
    params,
  });
