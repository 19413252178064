import { app } from '@/app';
import { router } from '@/router';
import { initSentry } from '@/app/services';

router.isReady().then(async () => {
  console.log('[router] router is ready');

  await initSentry(app, router);

  app.mount('#app');
});
