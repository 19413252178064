import type { RouteRecordRaw } from 'vue-router';

export default {
  path: '/billing',
  component: () => import('@/views/dashboard/billing/root.vue'),
  children: [
    {
      path: '',
      name: 'billing',
      component: () => import('@/views/dashboard/billing/billing.vue'),
    },
    {
      path: 'promo',
      name: 'billing/promo',
      component: () => import('@/views/dashboard/billing/promocode.vue'),
    },
  ],
} satisfies RouteRecordRaw;
