import { router } from '@/router';
import cookie from 'js-cookie';
import { baseRequest } from '@/shared/api/';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { toast } from 'vue-sonner';

baseRequest.interceptors.request.use(
  (config) => {
    if (
      config.headers &&
      (config.method === 'post' || config.method === 'delete' || config.method === 'put')
    ) {
      const csrftoken = cookie.get('csrftoken');
      if (!csrftoken) {
        throw new Error('csrftoken is not defined.');
      }
      config.headers.set('X-CSRFToken', csrftoken);
    }

    if (config.params) {
      config.params = decamelizeKeys(config.params);
    }

    if (config.data && !(config.data instanceof FormData)) {
      config.data = decamelizeKeys(config.data);
    }

    config.withCredentials = true;

    return config;
  },
  (err) => Promise.reject(err),
);

baseRequest.interceptors.response.use(
  (response) => {
    if (response.data && response.headers['content-type'] === 'application/json') {
      response.data = camelizeKeys(response.data);
    }
    return response;
  },
  async (error) => {
    if (error.response && error.response.config.method !== 'get' && error.response.status === 400) {
      toast.error('Bad Request', {
        description: error.response.data.message,
      });
    }

    if (error.response && error.response.config.method !== 'get' && error.response.status === 500) {
      toast.error('Bad Request', {
        description: 'Something goes wrong, please try again later.',
      });
    }

    if (error.response && error.response.status === 401) {
      toast.error('Authentication failed', {
        description: 'Unable to authenticate you.',
      });

      console.log('[request] redirect to login page');

      router
        .push({
          name: 'login',
          query: {
            next: router.currentRoute.value.path.includes('auth')
              ? '/'
              : router.currentRoute.value.path,
          },
        })
        .catch((e) => window.console.error(e));
    }

    if (error.response && error.response.status === 302) {
      window.location.replace(error.response.headers['redirect']);
    }

    return Promise.reject(error);
  },
);
