<template>
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.77 12.27c0-2.086.752-3.996 1.998-5.474l3.05 3.049a4.199 4.199 0 0 0-.767 2.424c0 .882.27 1.701.734 2.378l-3.1 2.996a8.463 8.463 0 0 1-1.914-5.374Zm2.963 6.447 3.104-3a4.199 4.199 0 0 0 2.432.77c.903 0 1.739-.283 2.425-.765l3.049 3.049a8.465 8.465 0 0 1-5.474 1.997 8.466 8.466 0 0 1-5.536-2.05Zm12.068-1.01a8.464 8.464 0 0 0 1.967-5.438c0-2.115-.773-4.049-2.05-5.536l-3.002 3.104c.486.688.772 1.527.772 2.432 0 .886-.273 1.707-.74 2.386l3.053 3.052Zm-4.154-8.922a4.198 4.198 0 0 0-2.378-.734c-.885 0-1.707.273-2.385.739L6.832 5.738a8.464 8.464 0 0 1 5.437-1.967c2.039 0 3.91.718 5.374 1.914l-2.996 3.1ZM12.27 2.27c-5.522 0-9.998 4.476-9.998 9.998 0 5.522 4.476 9.999 9.998 9.999 5.522 0 9.999-4.477 9.999-9.999s-4.477-9.998-9.999-9.998Zm-2.718 9.998a2.718 2.718 0 1 1 5.437 0 2.718 2.718 0 0 1-5.437 0Z"
      fill="currentColor"
    />
  </svg>
</template>
