import type { Profile } from '@/entities/profile';
import type { RouteLocationNormalized } from 'vue-router';
import { useQueryClient } from '@tanstack/vue-query';
import { getDefaultProject, getProject } from '@/entities/project';
import { IS_BILLING_ENABLED } from '@/shared/config';

export async function dashboardGuard(to: RouteLocationNormalized) {
  const queryClient = useQueryClient();
  const currentProjectUuid = to.params.project_uuid as string | undefined;
  const profile = queryClient.getQueryData<Profile>(['profile']);

  const isUserVerified = IS_BILLING_ENABLED ? profile?.isVerified : true;
  const isUserSettled = profile?.isEmailConfirmed && isUserVerified;
  const isUserMember = profile?.isMember && profile.isEmailConfirmed;
  const isUserOnboarded = isUserSettled || isUserMember;

  if (!isUserOnboarded) {
    return {
      name: 'onboarding',
    };
  }

  try {
    const project = await queryClient.ensureQueryData({
      queryKey: ['project', currentProjectUuid ?? 'default'],
      queryFn: () =>
        currentProjectUuid
          ? getProject(currentProjectUuid).then((data) => data.project)
          : getDefaultProject().then((data) => data.project),
      gcTime: Infinity,
      staleTime: Infinity,
    });

    queryClient.setQueryData(['project', project.uuid], project);

    if (to.fullPath === '/') {
      return {
        name: 'project',
        params: { project_uuid: project.uuid },
      };
    }
  } catch (error) {
    return {
      name: '500',
    };
  }
}
