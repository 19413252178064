<template>
  <aside
    ref="container"
    :class="[
      'bg-background fixed bottom-0 left-0 top-16 z-30 flex -translate-x-[16rem] flex-wrap border-r transition-transform lg:translate-x-0',
      isSidebarOpened ? 'translate-x-0' : '',
    ]"
  >
    <div class="flex h-full w-52 flex-1 flex-col">
      <!-- <header>
        <div class="p-4 pt-6">
          <router-link
            :to="{ name: 'project', params: { project_uuid: 'default' } }"
            class="flex items-center"
          >
            <v-logo-gradient />
            <span class="ml-3 text-lg font-medium">SimplyStack</span>
          </router-link>
        </div>
        <div class="space-y-4 p-4">
          <v-projects-list />
        </div>
      </header> -->
      <main class="flex flex-1 flex-col justify-between overflow-y-auto overflow-x-hidden">
        <div>
          <v-nav-links />
          <v-additional-links />
          <div v-if="isMember && !isVerified" class="px-2">
            <v-unlock-features />
          </div>
        </div>
      </main>
      <footer class="space-y-4 p-4 text-center">
        <v-theme-switcher />
      </footer>
    </div>
  </aside>
</template>

<script setup lang="ts">
import VUnlockFeatures from './unlock-features.vue';
import VThemeSwitcher from '@/shared/ui/theme-switcher.vue';
import VProjectsList from '@/widgets/sidebar/projects-list.vue';
import VLogoGradient from '@/components/icons/LogoGradient.vue';
import VNavLinks from './nav-links.vue';
import VAdditionalLinks from './additional-links.vue';
import { useClickAway } from '@/shared/hooks';
import { useAppStore } from '@/app/store';
import { storeToRefs } from 'pinia';
import { useProfile, useProfileStatus } from '@/entities/profile';

const { isSidebarOpened } = storeToRefs(useAppStore());
const { element: container } = useClickAway(() => {
  isSidebarOpened.value = false;
});
const { data: profile } = useProfile();
const { isMember, isVerified } = useProfileStatus(profile);
</script>
