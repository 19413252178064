<template>
  <div class="p-8">
    <div class="text-muted-foreground mb-4 text-xs font-medium uppercase">Resources</div>
    <ul class="space-y-2">
      <router-link v-slot="{ href, navigate, isActive }" :to="{ name: 'security' }" custom>
        <li :class="[isActive ? '' : 'text-muted-foreground hover:text-foreground']">
          <a :href="href" class="flex items-center space-x-3" @click="navigate">
            <v-security-icon :class="{ 'text-primary': isActive }" class="h-6 w-6" />
            <span :class="[isActive ? 'font-medium' : '']">Security</span>
          </a>
        </li>
      </router-link>
      <router-link v-slot="{ href, navigate, isActive }" :to="{ name: 'userscripts' }" custom>
        <li :class="[isActive ? '' : 'text-muted-foreground hover:text-foreground']">
          <a :href="href" class="flex items-center space-x-3" @click="navigate">
            <v-userscripts-icon :class="{ 'text-primary': isActive }" class="h-6 w-6" />
            <span :class="[isActive ? 'font-medium' : '']">Userscripts</span>
          </a>
        </li>
      </router-link>
      <router-link
        v-if="IS_HELPDESK_ENABLED"
        v-slot="{ href, navigate, isActive }"
        :to="{ name: 'get-help' }"
        custom
      >
        <li :class="[isActive ? '' : 'text-muted-foreground hover:text-foreground']">
          <a :href="href" class="flex items-center space-x-3" @click="navigate">
            <v-support-icon :class="{ 'text-primary': isActive }" class="h-6 w-6" />
            <span :class="[isActive ? 'font-medium' : '']">Support</span>
          </a>
        </li>
      </router-link>
      <router-link
        v-if="isUserComplete"
        v-slot="{ href, navigate, isActive }"
        :to="{ name: 'api' }"
        custom
      >
        <li :class="[isActive ? '' : 'text-muted-foreground hover:text-foreground']">
          <a :href="href" class="flex items-center space-x-3" @click="navigate">
            <v-api-icon :class="{ 'text-primary': isActive }" class="h-6 w-6" />
            <span :class="[isActive ? 'font-medium' : '']">API</span>
          </a>
        </li>
      </router-link>
    </ul>
  </div>
</template>

<script setup lang="ts">
import VApiIcon from './icons/Api.vue';
import VUserscriptsIcon from './icons/Userscripts.vue';
import VSecurityIcon from './icons/Security.vue';
import VSupportIcon from './icons/Support.vue';
import { IS_HELPDESK_ENABLED } from '@/shared/config';
import { useProfile, useProfileStatus } from '@/entities/profile';

const { data: profile } = useProfile();
const { isUserComplete } = useProfileStatus(profile);
</script>
