import type { RouteRecordRaw } from 'vue-router';

export default {
  meta: { feature: 'kubernetes' },
  path: 'iaas/kubernetes/:uuid',
  component: () => import('@/views/dashboard/iaas/kubernetes/kubernetes/root.vue'),
  redirect: () => ({ name: 'kubernetes/overview' }),
  children: [
    {
      path: 'overview',
      name: 'kubernetes/overview',
      component: () => import('@/views/dashboard/iaas/kubernetes/kubernetes/overview.vue'),
    },
    {
      path: 'nodes',
      name: 'kubernetes/nodes',
      component: () => import('@/views/dashboard/iaas/kubernetes/kubernetes/nodes.vue'),
    },
    {
      path: 'pools',
      name: 'kubernetes/pools',
      component: () => import('@/views/dashboard/iaas/kubernetes/kubernetes/pools.vue'),
    },
    {
      path: 'settings',
      name: 'kubernetes/settings',
      component: () => import('@/views/dashboard/iaas/kubernetes/kubernetes/settings.vue'),
    },
  ],
} satisfies RouteRecordRaw;
