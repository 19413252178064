import { type UseQueryOptions, useQuery } from '@tanstack/vue-query';
import { type Team, getTeams } from '@/entities/team';
import { type Ref } from 'vue';
import { type ResponseMeta } from '@/shared/api';

interface Response {
  teams: Team[];
  meta: ResponseMeta;
}

export function useTeams<T = Response>(
  options?: UseQueryOptions<Response, unknown, T>,
  params?: Ref<{ page?: number; query?: string }>,
) {
  return useQuery({
    queryKey: ['teams', params],
    queryFn: () => getTeams(params?.value),
    ...options,
  });
}
