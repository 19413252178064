import type { RouteRecordRaw } from 'vue-router';

export default {
  path: 'api',
  name: 'api',
  component: () => import('@/views/dashboard/api/root.vue'),
  redirect: () => ({ name: 'api/tokens' }),
  children: [
    {
      path: 'tokens',
      name: 'api/tokens',
      component: () => import('@/views/dashboard/api/api.vue'),
    },
    {
      meta: { feature: 'buckets' },
      path: 'buckets-keys',
      name: 'api/buckets-keys',
      component: () => import('@/views/dashboard/api/buckets-keys.vue'),
    },
  ],
} satisfies RouteRecordRaw;
