import type { RouteRecordRaw } from 'vue-router';

export default {
  meta: { feature: 'buckets' },
  path: 'buckets/create',
  component: () => import('@/views/dashboard/storages/buckets/create/root.vue'),
  children: [
    {
      path: '',
      name: 'bucket/create',
      component: () => import('@/views/dashboard/storages/buckets/create/create.vue'),
    },
  ],
} satisfies RouteRecordRaw;
