import type { Profile } from '@/entities/profile';
import { IS_BILLING_ENABLED } from '@/shared/config';
import { useQueryClient } from '@tanstack/vue-query';

export function onboardingGuard() {
  const queryClient = useQueryClient();

  const profile = queryClient.getQueryData<Profile>(['profile']);

  const isUserVerified = IS_BILLING_ENABLED ? profile?.isVerified : true;
  const isUserSettled = profile?.isEmailConfirmed && isUserVerified;
  const isUserMember = profile?.isMember && profile.isEmailConfirmed;
  const isUserOnboarded = isUserSettled || isUserMember;

  if (isUserOnboarded) {
    return {
      name: 'profile',
    };
  }
}
