<script lang="ts" setup>
import type { HTMLAttributes } from 'vue';
import { cn } from '../../util';

const props = defineProps<{
  class?: HTMLAttributes['class'];
}>();
</script>

<template>
  <p :class="cn('text-muted-foreground text-sm', props.class)">
    <slot />
  </p>
</template>
