import { type Notification } from '@/entities/notification';
import { type RequestMeta, type ResponseMeta } from '@/shared/api';
import request from '@/shared/api/request';

export const getNotifications = (
  params: RequestMeta,
): Promise<{
  notifications: Notification[];
  meta: ResponseMeta;
}> =>
  request({
    url: '/notifications/',
    params,
  });
