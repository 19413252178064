import type { RouteRecordRaw } from 'vue-router';

export default {
  path: 'networking/domains/:name',
  component: () => import('@/views/dashboard/networking/domains/root.vue').then((m) => m.default),
  children: [
    {
      meta: { feature: 'domains' },
      path: '',
      name: 'domain',
      component: () =>
        import('@/views/dashboard/networking/domains/records.vue').then((m) => m.default),
    },
  ],
} satisfies RouteRecordRaw;
