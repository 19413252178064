<script setup lang="ts">
import { useVModel } from '@vueuse/core';
import type { HTMLAttributes } from 'vue';
import { cn } from '../../util';

const props = defineProps<{
  label?: string;
  placeholder?: string;
  defaultValue?: string | number;
  modelValue?: string | number;
  class?: HTMLAttributes['class'];
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', payload: string | number): void;
}>();

const modelValue = useVModel(props, 'modelValue', emits, {
  passive: true,
  defaultValue: props.defaultValue,
});
</script>

<template>
  <label class="relative">
    <span
      v-if="label"
      class="text-muted-foreground absolute left-3 top-1 text-[10px] font-medium uppercase"
      >{{ label }}</span
    >
    <input
      :class="
        cn(
          'border-input read-only:bg-muted/40 ring-offset-card placeholder:text-muted-foreground focus-visible:ring-ring bg-card file:bg-card disabled:bg-muted/50 disabled:text-muted-foreground flex h-11 w-full rounded-md border px-3 pt-4 text-sm file:border-0 file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed',
          props.class,
        )
      "
      v-model="modelValue"
      :placeholder="placeholder"
      v-bind="$attrs"
    />
  </label>
</template>
