<template>
  <div
    v-if="isSearchOpened"
    class="fixed inset-0 z-50 flex h-screen flex-col bg-black/20 backdrop-blur-sm lg:p-16"
  >
    <div
      ref="modal"
      class="bg-popover mx-auto flex min-h-0 w-full max-w-2xl flex-col rounded-md shadow-2xl ring-1 ring-black/5"
    >
      <v-search-form
        ref="searchForm"
        v-model:query="query"
        :loading="loading"
        @close="isSearchOpened = false"
        @highlight:up="highlight('up')"
        @highlight:down="highlight('down')"
        @keydown.enter="handleKeydownEnter"
      />
      <main ref="container" class="flex-1 space-y-6 overflow-auto py-3">
        <template v-if="query && query.length >= 2">
          <v-results-section
            v-if="Object.keys(results).length > 0"
            :results="results"
            :highlighted-index="highlightedIndex"
            @mouseover="(index) => (highlightedIndex = index)"
            @click="handleClick"
          />
          <div v-else-if="!typing" class="text-muted-foreground py-6 text-center">
            Nothing found for <span class="text-primary font-medium">"{{ query }}"</span>
          </div>
        </template>
        <template v-else>
          <v-history-section
            v-if="history.length > 0"
            :history="history"
            :highlighted-index="highlightedIndex"
            @mouseover="(index) => (highlightedIndex = index)"
            @click="handleClick"
          />
          <v-history-empty-section v-else />
        </template>
      </main>
      <div class="border-t px-6 py-3 text-xs">
        <span class="font-medium uppercase">tip: </span>
        <span class="text-muted-foreground">Use keyboard arrows ⬆ and ⬇ to navigate.</span>
      </div>
    </div>
    <div tabindex="0" @focus.stop="searchForm?.focus()"></div>
  </div>
</template>

<script setup lang="ts">
import VSearchForm from './search-form.vue';
import VResultsSection from './sections/results.vue';
import VHistorySection from './sections/history.vue';
import VHistoryEmptySection from './sections/history-empty.vue';
import { useClickAway } from '@/shared/hooks';
import { useAppStore } from '@/app/store';
import { onMounted, Ref, ref, watch, nextTick } from 'vue';
import { storeToRefs } from 'pinia';
import { search } from '@/entities/search';
import { useRoute, useRouter } from 'vue-router';
import debounce from 'lodash.debounce';
import { SEARCH_HISTORY_UUID_KEY } from '@/shared/config';

const HISTORY_MAX_LENGTH = 5;

const route = useRoute();
const router = useRouter();
const { isSearchOpened } = storeToRefs(useAppStore());
const { element: modal } = useClickAway(() => {
  isSearchOpened.value = false;
});
const searchForm = ref();
const loading = ref(false);
const typing = ref(false);
const query = ref('');
const results = ref<{ type: string; projectUuid: string }[]>([]);
const history = ref([]);
const highlightedIndex = ref(-1);
const container = ref() as Ref<HTMLElement>;

const writeHistory = (selected) => {
  const isExist = (_item, _selected) =>
    _item.type === _selected.type &&
    (_item.id ? _item.id : _item.uuid ? _item.uuid : _item.name) ===
      (_selected.id ? _selected.id : _selected.uuid ? _selected.uuid : _selected.name);

  if (history.value.some((item) => isExist(item, selected))) {
    history.value = history.value.filter((item) => !isExist(item, selected));
  }

  if (history.value.length >= HISTORY_MAX_LENGTH) {
    history.value = history.value.splice(0, HISTORY_MAX_LENGTH - 1);
  }

  window.localStorage.setItem(
    SEARCH_HISTORY_UUID_KEY,
    JSON.stringify([selected, ...history.value]),
  );
};

const redirectToItemByType = (item) => {
  switch (item.type) {
    case 'stacklet':
      router.push({
        name: 'stacklet/overview',
        params: { stacklet_id: item.id },
      });
      break;
    case 'bucket':
      router.push({
        name: 'bucket/files',
        params: { bucket_id: item.name },
      });
      break;
    case 'domain':
      router.push({
        name: 'domain',
        params: { name: item.name },
      });
      break;
    case 'firewall':
      router.push({
        name: 'firewall/rules',
        params: { uuid: item.uuid },
      });
      break;
    case 'registry':
      router.push({
        name: 'registry',
      });
      break;
    case 'load_balancer':
      router.push({
        name: 'loadbalancer/stacklets',
        params: { uuid: item.uuid },
      });
      break;
    case 'database':
      router.push({
        name: 'database/overview',
        params: { database_uuid: item.uuid },
      });
      break;
  }
};

const handleKeydownEscape = (e: KeyboardEvent) => {
  if (isSearchOpened.value && e.key === 'Escape') {
    isSearchOpened.value = false;
  }
};

const handleKeydownEnter = () => {
  if (highlightedIndex.value === -1) return;

  const item = (query.value ? results.value : history.value)[highlightedIndex.value];

  writeHistory(item);

  redirectToItemByType(item);

  redirectToItemProject(item);

  isSearchOpened.value = false;
};

const handleClick = () => {
  const item = (query.value ? results.value : history.value)[highlightedIndex.value];

  writeHistory(item);

  redirectToItemByType(item);

  redirectToItemProject(item);

  isSearchOpened.value = false;
};

const highlight = (direction: 'down' | 'up') => {
  const arr = query.value.length >= 2 ? results.value : history.value;

  if (highlightedIndex.value === arr.length) return;

  if (direction === 'down') {
    highlightedIndex.value += 1;
    if (highlightedIndex.value === arr.length) {
      highlightedIndex.value = 0;
    }
  }

  if (direction === 'up') {
    highlightedIndex.value -= 1;
    if (highlightedIndex.value < 0) {
      highlightedIndex.value = arr.length - 1;
    }
  }

  nextTick(() => {
    container.value
      .querySelectorAll('li')
      [highlightedIndex.value].scrollIntoView({ behavior: 'smooth', block: 'center' });
  });
};

function redirectToItemProject(item) {
  // if (item.projectUuid !== currentProjectUuid.value) {
  //   currentProjectUuid.value = item.projectUuid;
  // }
}

const request = debounce((string: string) => {
  loading.value = true;

  search({ query: string, projectUuid: route.params.project_uuid as string }).then((data) => {
    results.value = data.results;

    loading.value = false;

    typing.value = false;
  });
}, 500);

onMounted(() => {});

watch(query, (string) => {
  typing.value = true;

  if (string.length === 0) {
    results.value = [];
  }

  if (string.length < 2) {
    highlightedIndex.value = -1;
    return;
  }

  request(string);
});

watch(
  () => route.path,
  () => {
    isSearchOpened.value = false;
  },
);

watch(
  isSearchOpened,
  (opened) => {
    if (opened) {
      window.document.addEventListener('keydown', handleKeydownEscape);

      history.value = JSON.parse(window.localStorage.getItem(SEARCH_HISTORY_UUID_KEY) || '[]');
    } else {
      window.document.removeEventListener('keydown', handleKeydownEscape);
      query.value = '';
      results.value = [];
      highlightedIndex.value = -1;
    }

    window.document.body.classList[opened ? 'add' : 'remove']('overflow-hidden');
  },
  { immediate: true },
);
</script>
