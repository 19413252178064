export default {
  path: 'stacklets',
  component: () => import('@/views/dashboard/stacklets/root.vue'),
  children: [
    {
      path: '',
      name: 'stacklets',
      component: () => import('@/views/dashboard/stacklets/stacklets.vue'),
    },
  ],
};
