import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export function getTimeFromNow(date: Date | string) {
  return dayjs(date).fromNow();
}

export function formatDateTime(date: Date | string): string {
  return `${dayjs(date).format('ddd, MMMM D, YYYY')} at ${dayjs(date).format('HH:mm A')}`;
}

export function formatUnixTimestamp(timestamp: number, full: boolean) {
  const date = new Date(timestamp * 1000);
  let hours = date.getHours();
  let minutes = `0${date.getMinutes()}`;
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const day = date.getDate();
  let month = date.getMonth();
  month = month < 9 ? `0${month + 1}` : month + 1;
  const year = date.getFullYear();

  hours %= 12;
  hours = hours || 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  // Will display time in 20-12-2017 10:30 PM format
  if (full) {
    return `${day}-${month}-${year} ${hours}:${minutes.substr(-2)} ${ampm}`;
  }
  // Will display time in 10:30 PM format
  return `${hours}:${minutes.substr(-2)} ${ampm}`;
}
