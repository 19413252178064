import type { RouteRecordRaw } from 'vue-router';

export default {
  path: 'container-registry',
  component: () => import('@/views/dashboard/iaas/container-registry/root.vue'),
  children: [
    {
      meta: { feature: 'container_registry' },
      path: '',
      name: 'container-registry',
      component: () => import('@/views/dashboard/iaas/container-registry/list.vue'),
    },
  ],
} satisfies RouteRecordRaw;
