import type { RouteRecordRaw } from 'vue-router';

export default {
  path: 'iaas/container-registry/registry',
  component: () => import('@/views/dashboard/iaas/container-registry/registry/root.vue'),
  children: [
    {
      meta: { feature: 'container_registry' },
      path: '',
      name: 'registry',
      component: () => import('@/views/dashboard/iaas/container-registry/registry/images.vue'),
    },
    {
      meta: { feature: 'container_registry' },
      path: 'settings',
      name: 'registry/settings',
      component: () => import('@/views/dashboard/iaas/container-registry/registry/settings.vue'),
    },
  ],
} satisfies RouteRecordRaw;
