import type { RouteRecordRaw } from 'vue-router';

export default {
  meta: { feature: 'buckets' },
  path: 'buckets/:bucket_id',
  component: () => import('@/views/dashboard/storages/buckets/bucket/root.vue'),
  redirect: () => ({ name: 'bucket/files' }),
  children: [
    {
      path: 'files',
      name: 'bucket/files',
      component: () => import('@/views/dashboard/storages/buckets/bucket/files.vue'),
    },
    {
      path: 'settings',
      name: 'bucket/settings',
      component: () => import('@/views/dashboard/storages/buckets/bucket/settings/settings.vue'),
    },
  ],
} satisfies RouteRecordRaw;
