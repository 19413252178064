import 'ui/main.css';
import '@/shared/css/main.css';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';
import '@fontsource/rubik/700.css';
import { createApp } from 'vue';
import ui from 'ui';
import Root from '@/app/root.vue';
import { router } from '@/router';
import '@/request';
import { createPinia } from 'pinia';
import { VueQueryPlugin } from '@tanstack/vue-query';

export const app = createApp(Root);
const pinia = createPinia();

app.use(pinia);
app.use(ui);
app.use(VueQueryPlugin);
app.use(router);

console.log('init app');
