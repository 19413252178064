import type { RouteRecordRaw } from 'vue-router';

export default {
  path: 'networking/firewalls/:uuid',
  component: () => import('@/views/dashboard/networking/firewalls/root.vue'),
  redirect: () => ({ name: 'firewall/rules' }),
  children: [
    {
      path: 'rules',
      name: 'firewall/rules',
      component: () => import('@/views/dashboard/networking/firewalls/rules.vue'),
    },
    {
      path: 'stacklets',
      name: 'firewall/stacklets',
      component: () => import('@/views/dashboard/networking/firewalls/stacklets.vue'),
    },
  ],
} satisfies RouteRecordRaw;
