<template>
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g stroke="currentColor" stroke-width="1.5" stroke-linecap="round">
      <path
        d="m8.143 3 5.143 2.4-5.143 2.4L3 5.4 8.143 3ZM15.857 7.8 21 10.2l-5.143 2.4-5.143-2.4 5.143-2.4Z"
        stroke-linejoin="round"
      />
      <path d="M3 5.4v7.975L8.143 16.2M10.714 10.2v7.975L15.857 21 21 18.175V10.2" />
    </g>
  </svg>
</template>
