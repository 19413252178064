import type { RouteRecordRaw } from 'vue-router';

export default {
  path: 'networking',
  name: 'networking',
  component: () => import('@/views/dashboard/networking/root.vue'),
  children: [
    {
      meta: { feature: 'floating_ips' },
      path: 'floating-ips',
      name: 'networking/floating-ips',
      component: () => import('@/views/dashboard/networking/floating-ips/floating-ips.vue'),
    },
    {
      meta: { feature: 'domains' },
      path: 'domains',
      name: 'networking/domains',
      component: () => import('@/views/dashboard/networking/domains/list.vue'),
    },
    {
      meta: { feature: 'firewalls' },
      path: 'firewalls',
      name: 'networking/firewalls',
      component: () => import('@/views/dashboard/networking/firewalls/list.vue'),
    },
    {
      meta: { feature: 'load_balancers' },
      path: 'load-balancers',
      name: 'networking/loadbalancers',
      component: () => import('@/views/dashboard/networking/loadbalancers/list.vue'),
    },
    {
      meta: { feature: 'vpc' },
      path: 'vpc',
      name: 'networking/vpc',
      component: () => import('@/views/dashboard/networking/vpc/index.vue'),
    },
  ],
} satisfies RouteRecordRaw;
