import type { RouteRecordRaw } from 'vue-router';

export default {
  path: '/account',
  component: () => import('@/views/dashboard/account/root.vue'),
  redirect: () => ({ name: 'profile' }),
  children: [
    { path: '', redirect: { name: 'profile' } },
    {
      path: 'profile',
      name: 'profile',
      component: () => import('@/views/dashboard/account/profile.vue'),
    },
    {
      path: 'security',
      name: 'profile/logs',
      component: () => import('@/views/dashboard/account/logs.vue'),
    },
    {
      path: 'notifications',
      name: 'profile/notifications',
      component: () => import('@/views/dashboard/account/notifications.vue'),
    },
  ],
} satisfies RouteRecordRaw;
