import type { RouteRecordRaw } from 'vue-router';

export default {
  meta: { feature: 'buckets' },
  path: 'buckets',
  component: () => import('@/views/dashboard/storages/buckets/root.vue'),
  children: [
    {
      path: '',
      name: 'buckets',
      component: () => import('@/views/dashboard/storages/buckets/list.vue'),
    },
  ],
} satisfies RouteRecordRaw;
