import { z } from 'zod';

export const MemberSchema = z.object({
  email: z.string(),
  firstName: z.string(),
  gravatarUrl: z.string(),
  lastName: z.string(),
  role: z.enum(['Owner', 'Member'] as const),
  status: z.enum(['Joined', 'Pending'] as const),
  teamUuid: z.string(),
  uuid: z.string(),
});

export const TeamSchema = z.object({
  uuid: z.string(),
  name: z.string(),
  color: z.string(),
  members: z.array(MemberSchema),
  ownerUuid: z.string(),
  createdAt: z.string(),
});

export type Member = z.infer<typeof MemberSchema>;
export type Team = z.infer<typeof TeamSchema>;
export type MemberStatus = z.infer<typeof MemberSchema>['status'];
export type MemberRole = z.infer<typeof MemberSchema>['role'];
