import type { RouteRecordRaw } from 'vue-router';

export default {
  meta: { feature: 'kubernetes' },
  path: 'iaas/kubernetes/create',
  component: () => import('@/views/dashboard/iaas/kubernetes/root.vue'),
  children: [
    {
      path: '',
      name: 'kubernetes/create',
      component: () => import('@/views/dashboard/iaas/kubernetes/create/create.vue'),
    },
  ],
} satisfies RouteRecordRaw;
