<script setup lang="ts">
import type { HTMLAttributes } from 'vue';
import { useVModel } from '@vueuse/core';
import { useFieldError } from 'vee-validate';
import { cn } from '../../util';

const props = defineProps<{
  name: string;
  defaultValue?: string | number;
  modelValue?: string | number;
  class?: HTMLAttributes['class'];
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', payload: string | number): void;
}>();

const modelValue = useVModel(props, 'modelValue', emits, {
  passive: true,
  defaultValue: props.defaultValue,
});

const error = useFieldError(props.name);
</script>

<template>
  <input
    :aria-invalid="!!error"
    v-model="modelValue"
    :class="
      cn(
        'aria-[invalid=true]:border-destructive border-input read-only:bg-muted/40 read-only:text-foreground/70 ring-offset-card placeholder:text-muted-foreground focus-visible:ring-ring bg-card file:bg-card disabled:bg-muted/50 disabled:text-muted-foreground flex h-11 w-full rounded-md border px-3 py-2 text-sm file:border-0 file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed',
        props.class,
      )
    "
  />
</template>
