<template>
  <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 4h6.5l2 1.667H21V19H3V4Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
    <path
      d="m14.571 9.714 2.572 2.572-2.572 2.571M9.429 9.714l-2.572 2.572 2.572 2.571"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
  </svg>
</template>
